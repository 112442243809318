import React from "react"
import { Edit } from "react-admin"
import { PartnerForm } from "./PartnerForm"

export const PartnerEdit = (props) => {
    return (
        <Edit {...props}>
            <PartnerForm />
        </Edit>
    )
}
