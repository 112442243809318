import React from "react"
import { Button, useMutation } from "react-admin"
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone"
import HighlightOffTwoToneIcon from "@material-ui/icons/HighlightOffTwoTone"

const useUpdateStatus = ({ status, record, resource }) => {
    const [update, { loading }] = useMutation({
        type: "update",
        resource,
        payload: {
            id: record.id,
            data: {
                status
            }
        }
    })
    return { update, loading }
}

export const ValidateButton = ({ record, resource }) => {
    const { update, loading } = useUpdateStatus({ status: "validated", record, resource })
    return <Button onClick={update} disabled={loading} startIcon={<CheckCircleTwoToneIcon />} label="Accept" />
}
export const RejectButton = ({ record, resource }) => {
    const { update, loading } = useUpdateStatus({ status: "rejected", record, resource })
    return <Button onClick={update} disabled={loading} startIcon={<HighlightOffTwoToneIcon />} label="Reject" />
}
