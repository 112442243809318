import { ArrayInput, FormTab, ImageField, ImageInput, NumberInput, SimpleFormIterator, TabbedForm, TextInput } from "react-admin"
import React from "react"

export const PartnerForm = (props) => (
    <TabbedForm {...props}>
        <FormTab label="Configuration">
            <TextInput source="fullName" label="Nom" />
            <ImageInput source="logo" maxSize={5000000} label="Logo">
                <ImageField source="src" />
            </ImageInput>
        </FormTab>
        <FormTab label="Rewards">
            <ArrayInput source="rewards">
                <SimpleFormIterator>
                    <TextInput source="name" label="Nom de la récompense" />
                    <NumberInput source="price" label="Prix en euros" />
                </SimpleFormIterator>
            </ArrayInput>
        </FormTab>
    </TabbedForm>
)
