import React from "react"
import { Datagrid, EditButton, Filter, FunctionField, List, ReferenceField, SelectInput, TextField } from "react-admin"
import HourglassFullTwoToneIcon from "@material-ui/icons/HourglassFullTwoTone"
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone"
import HighlightOffTwoToneIcon from "@material-ui/icons/HighlightOffTwoTone"
import { RejectButton, ValidateButton } from "./components/Buttons"

export const MissionsList = (props) => {
    return (
        <List {...props} title="Liste des missions" filters={<MissionsFilters />}>
            <Datagrid>
                <TextField source="location" />
                <TextField source="studentLevel" />
                <TextField source="subject" />
                <ReferenceField source="owner" reference="users" label="Association">
                    <TextField source="name" />
                </ReferenceField>
                <FunctionField
                    source="status"
                    label=""
                    render={(row) => {
                        switch (row.status) {
                            case "validated":
                                return <CheckCircleTwoToneIcon />
                            case "rejected":
                                return <HighlightOffTwoToneIcon />
                            default:
                                return <HourglassFullTwoToneIcon />
                        }
                    }}
                />
                <Buttons />
            </Datagrid>
        </List>
    )
}

const Buttons = (props) => (
    <div {...props}>
        <EditButton {...props} />
        <div>
            <ValidateButton {...props} />
        </div>
        <RejectButton {...props} />
    </div>
)

export const statusesChoices = [
    { id: "validated", name: "Validées" },
    { id: "rejected", name: "Refusées" },
    { id: "submitted", name: "Soumises" }
]
const MissionsFilters = (props) => {
    return (
        <Filter {...props}>
            <SelectInput source="status" alwaysOn choices={statusesChoices} />
        </Filter>
    )
}
