import * as React from "react"
import { DateInput, DeleteButton, Edit, FormWithRedirect, SaveButton, SelectInput, TextInput, Toolbar } from "react-admin"
import { Cell, Row } from "../components/Layout"
import { statusesChoices } from "./MissionsList"

export const MissionEdit = (props) => (
    <Edit {...props}>
        <Form />
    </Edit>
)

const Form = (props) => (
    <FormWithRedirect
        {...props}
        render={(formProps) => (
            <form {...formProps}>
                <Row>
                    <Cell>
                        <TextInput disabled label="Id" source="id" fullWidth />
                    </Cell>
                    <Cell>
                        <SelectInput label="Etat" source="status" fullWidth choices={statusesChoices} />
                    </Cell>
                </Row>
                <Cell>
                    <TextInput label="Description" source="description" fullWidth multiline />
                </Cell>
                <Row>
                    <Cell>
                        <DateInput label="Démare le" source="startAt" fullWidth />
                    </Cell>
                    <Cell>
                        <DateInput label="Fini le" source="endAt" fullWidth />
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <TextInput label="Lieu" source="location" fullWidth />
                    </Cell>
                    <Cell>
                        <TextInput label="Régularité" source="rates" fullWidth />
                    </Cell>
                    <Cell>
                        <TextInput label="Durée de la scéance" source="seance" fullWidth />
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <TextInput label="Matière" source="subject" fullWidth />
                    </Cell>
                    <Cell>
                        <TextInput label="Niveau de l'étudiant" source="studentLevel" fullWidth />
                    </Cell>
                </Row>
                <Toolbar>
                    <Row width="100%" justifyContent="flex-end">
                        <DeleteButton record={formProps.record} />
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <SaveButton saving={formProps.saving} handleSubmitWithRedirect={formProps.handleSubmitWithRedirect} />
                    </Row>
                </Toolbar>
            </form>
        )}
    />
)
