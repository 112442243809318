import React, { memo, useState } from "react"
import { Admin, cacheDataProviderProxy, Resource } from "react-admin"
import { FirebaseDataProvider } from "react-admin-firebase"
import { firebaseApp, firebaseConfig } from "./firebase"
import { MissionsList } from "./missions/MissionsList"
import { UsersList } from "./users/UsersList"
import { MessagesList } from "./messages/MessagesList"
import { MissionEdit } from "./missions/MissionEdit"
import { PartnersList } from "./partners/PartnersList"
import { PartnerCreate } from "./partners/PartnerCreate"
import { PartnerEdit } from "./partners/PartnerEdit"

export const AdminRoot = memo(() => {
    const [dataProvider] = useState(() =>
        cacheDataProviderProxy(
            FirebaseDataProvider(firebaseConfig, {
                app: firebaseApp
            })
        )
    )
    /*const [authProvider] = useState(() => FirebaseAuthProvider(firebaseConfig, {
    app:firebaseApp
  }))*/

    return (
        <Admin dataProvider={dataProvider}>
            <Resource name="missions" list={MissionsList} edit={MissionEdit} />
            <Resource name="users" list={UsersList} />
            <Resource name="messages" list={MessagesList} />
            <Resource name="partners" list={PartnersList} create={PartnerCreate} edit={PartnerEdit} />
        </Admin>
    )
})

export default AdminRoot
