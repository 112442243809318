import React from "react"
import { Create } from "react-admin"
import { PartnerForm } from "./PartnerForm"

export const PartnerCreate = (props) => {
    return (
        <Create {...props}>
            <PartnerForm />
        </Create>
    )
}
