import React from "react"
import { Datagrid, EditButton, List, TextField } from "react-admin"

export const UsersList = (props) => {
    return (
        <List {...props}>
            <Datagrid>
                <TextField source="name" />
                <TextField source="type" />
                <Buttons />
            </Datagrid>
        </List>
    )
}

const Buttons = (props) => (
    <div {...props}>
        <EditButton {...props} />
    </div>
)
